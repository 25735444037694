import React from "react";
import { Link } from "gatsby";

import styled from "styled-components";

const Menu = styled.div`
  background: black;
  color: white;
  width: 360px;
  flex-direction: column;
  justify-content: space-around;
  animation: opensidebar 300ms ease;
  display: ${props => (props.open ? "flex" : "none")};
  align-items: center;
  a {
    color: white;
    text-transform: uppercase;
    line-height: 28px;
    font-size: 1rem;
  }
  a:hover {
    color: rgb(255, 255, 255, 0.3);
  }
  .navigation {
    display: flex;
    flex-direction: column;
  }
  .contact {
    padding: 1rem;
    align-items:center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .contact address {
    max-width: 150px;
  }
  .email{
    text-transform: lowercase !important;
  }
  .contact > address,
  span,
  .nav-cta {
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 18px;
    font-style:normal;
    margin-bottom: 0.5rem;
    color: rgb(255, 255, 255, 0.3);
    &:hover {
      color: rgb(255, 255, 255, 0.6);
    }


  }
`;
const MenuSidebar = class extends React.Component {
  render() {
    return (
      <Menu
        role="navigation"
        aria-label="main-navigation"
        open={this.props.open}
      >
        <div className="navigation">
          <Link to="/" activeStyle={{ color: "var(--tbwa-yellow)" }}>
            {" "}
            Accueil
          </Link>
          <Link to="/about" activeStyle={{ color: "var(--tbwa-yellow)" }}>
            {" "}
            A propos
          </Link>
          <Link to="/projects" activeStyle={{ color: "var(--tbwa-yellow)" }}>
            Projets
          </Link>
          <Link to="/services" activeStyle={{ color: "var(--tbwa-yellow)" }}>
            Services
          </Link>
        </div>
        <div className="navigation">
          <Link to="/blog" activeStyle={{ color: "var(--tbwa-yellow)" }}>
            {" "}
            Blog
          </Link>
          <Link to="/carriere" activeStyle={{ color: "var(--tbwa-yellow)" }}>
            Carrière
          </Link>
          <Link to="/contact" activeStyle={{ color: "var(--tbwa-yellow)" }}>
            Contact
          </Link>
        </div>
        <div className="contact">
          <address>
            Lotissement Ben Haddadi, villa n°2, Chéraga Algérie,
          </address>
          <a className="nav-cta" href="tel:+213 23 35 84 07">
            {" "}
            +213 23 35 84 07,
          </a>
          <a className="nav-cta email" href="mailto:contact@tbwadjaz.com">
            contact@tbwadjaz.com
          </a>
        </div>
      </Menu>
    );
  }
};

export default MenuSidebar;
