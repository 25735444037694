import React from "react";
import { Link } from "gatsby";

import facebook from "../img/svg/facebook.svg";
import twitter from "../img/svg/twitter.svg";
import letter from "../img/svg/dynamic.svg";
import linkedin from "../img/svg/linkedin.svg";
import styled from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";

const FooterContainer = styled.footer`
  background: var(--tbwa-gray);
  text-align: center;
  padding: 40px;
  .social {
    img {
      width: 30px;
      margin-left: 10px;
    }
  }
  input {
    padding: 12px 5px;
    border: none;
    width: 100%;
  }
  address {
    max-width: 400px;
    margin: 10px auto;
    font-size: 18px;
    font-style: normal;
  }
  .newsletter > * {
    margin-left: 10px;
  }
  .newsletter {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0;
    justify-content: center;
    align-items: center;
    p {
      max-width: 300px;
      font-size: 20px;
    }
    form{
      display:flex;
      flex-wrap:wrap;
      button{
        background: var(--tbwa-yellow);
        color:white;
        width:100%;
        padding: 0.5rem;
        font-size:1rem;
      }
    }
    img {
      width: 50px;
    }
  }

  .copywrites {
    a,
    span {
      font-size: 16px;
    }
    a {
      color: var(--tbwa-dark-gray);
      margin-left: 5px;
      font-size: 14px;
    }
  }
`;

const Footer = class extends React.Component {
  state = {
    name: null,
    email: null,
    message: null
  };

  _handleSubmit =  (e) => {
    e.preventDefault();
    addToMailchimp(this.state.email, this.state)
    .then(({ msg, result }) => {

        if (result !== 'success') {
            throw msg;
        }
        this.setState({
          message: msg
        })
    })
    .catch((err) => {
        console.log('err', err);
        this.setState({
          message:err
        })
        alert(err);
    });
    // I recommend setting `result` to React state
    // but you can do whatever you want
  };
  render() {
    const {message} = this.state;
    return (
      <FooterContainer>
        <div className="newsletter">
          <img src={letter} />
          <p>
            Découvrez notre actualité et celle de notre réseau en vous
            inscrivant à notre Newsletter
          </p>
          <form onSubmit={this._handleSubmit}>
            <input
              placeholder="Votre email"
              type="email"
              name="email"
              required
            />
            <button style={{backgroundColor:"var(--tbwa-yellow)"}} type="submit">S'inscrire</button>
          </form>
          {message && <span>{message}</span>}
        </div>
        <div className="social">
          <a target="_blank" href="https://www.facebook.com/tbwadjaz">
            <img src={facebook} />
          </a>
          <a target="_blank" href="https://twitter.com/tbwadjaz">
            <img src={twitter} />
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/tbwa-djaz/">
            <img src={linkedin} />
          </a>
        </div>
        <address>
          Lotissement Ben Haddadi, villa n°2, Dar Diaf 16014, Chéraga, Algérie
        </address>
        <a target="_blank" href="https://tbwa.com/">
          TBWA\
        </a>
        <div className="copywrites">
          <span>TBWA\DJAZ© 2019 tous droits réservés</span>
          <Link to="/">Mentions légales </Link>
          <Link to="/">Politique de confidentialité </Link>
        </div>
      </FooterContainer>
    );
  }
};

export default Footer;
