import React from "react";
import { Link } from "gatsby";
import twitter from "../img/svg/twitter.svg";
import linkedin from "../img/svg/linkedin.svg";
import youtube from "../img/svg/youtube.svg";
import facebook from "../img/svg/facebook.svg";
import hamburger from "../img/svg/hamburger.svg";
import BackSlash from "../img/svg/backslash.svg";
import cancel from "../img/svg/cancel.svg";
import Logo from "../img/logo.svg";
import styled from "styled-components";

const NavbarMenu = styled.div`
  background: yellow;
  width: 80px;
  background: var(--tbwa-yellow);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  img {
    width: 20px;
    margin: 5px 0px;
  }
  .social {
    display: flex;
    flex-direction: column;
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .brand img {
    width: 160px;
    transform: rotate(-90deg);
  }
  .hamburger {
    background: #eeac0d;
    border-radius: 50%;
    padding: 4px 10px;
    margin-top: 10px;
    width: 25px;
    cursor: pointer;
    img {
      width: 100%;
      display: block;
    }
  }

  @media (max-width: 800px) {
    width: 50px;
  }
`;
const Navbar = class extends React.Component {
  render() {
    const { openHamburger, open } = this.props;
    return (
      <NavbarMenu role="navigation" aria-label="main-navigation">
        <div className="menu">
          <div className="hamburger" onClick={openHamburger}>
            <img src={open ? cancel : hamburger} />
          </div>
        </div>
        <div className="brand">
          <Link to="/" activeStyle={{ color: "var(--tbwa-yellow)" }}>
            <img src={Logo} />
          </Link>
        </div>
        <div className="social">
          <a target="_blank" href="https://www.facebook.com/tbwadjaz"><img src={facebook} /></a>
          <a target="_blank" href="https://twitter.com/tbwadjaz"><img src={twitter} /></a>
          <a target="_blank" href="https://www.linkedin.com/company/tbwa-djaz/"><img src={linkedin} /></a>
        </div>
      </NavbarMenu>
    );
  }
};

export default Navbar;
