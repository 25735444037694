import React from "react";
import Helmet from "react-helmet";
import Footer from "./Footer";
import Navbar from "../components/Navbar";
import useSiteMetadata from "./SiteMetadata";
import styled from "styled-components";
import { GlobalRootStyle } from "./global-style";
import "./all.css";
import MenuSidebar from "./menu";

const Container = styled.div`
  height: 100vh;
  display: flex;
  overflow-y: auto;
`;
class TemplateWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    if (this.state.open) {
      this.setState({
        open: false
      });
    }
    if (!this.state.open) {
      this.setState({
        open: true
      });
    }
  };
  closeHamburger = () => {
    // toggle the active boolean in the state
    const { open } = this.state;
    if (open) {
      this.setState({
        open: false
      });
    }
  };
  render() {
    return (
      <div>
        <GlobalRootStyle />

        <Helmet>
          <html lang="fr" />
          <title>TBWA\ DJAZ</title>
          <meta name="description" content="Nous sommes une agence de conseil en communication en Afrique du nord membre du réseau mondial TBWA" />
          <meta property="og:type" content="business.business" />
          <meta property="og:title" content="TBWA\ DJAZ" />
          <meta property="og:url" content="/http://tbwadjaz.com" />
        </Helmet>
        <Container>
          <Navbar
            open={this.state.open}
            openHamburger={this.toggleHamburger}
            closeHamburger={this.closeHamburger}
          />
          <MenuSidebar open={this.state.open} />
          <div style={{ width: "100%", overflowY: "auto" }}>
            {this.props.children}
            <Footer />
          </div>
        </Container>
      </div>
    );
  }
}

export default TemplateWrapper;
